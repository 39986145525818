import React from "react"
import { Link } from "gatsby"


import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionHeader from "../components/SectionHeader"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

const Thanks = () => {
   return (
      <Layout>
         <SEO title="Thanks"/>
         <div style={{backgroundColor:'darkGrey', padding: '16px'}}>
            <SectionHeader title='Thanks'/>
            <Card>
               <CardContent>
                  Thanks for your message, we'll get back to you shortly!
                  <Link to="/"> Return to Home Page </Link>
               </CardContent>
            </Card>
         </div>
      </Layout>
   )
}

export default Thanks;